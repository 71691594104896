<template>
  <v-row class="ma-0 pa-0 pt-6">
    <v-col cols="12" lg="6" xl="7">
      <v-card flat>
        <v-tabs id="tabs" background-color="#F6F6F6"
                class="rounded-xl"
                v-model="tab" grow centered hide-slider
                :style="`height: ${$vuetify.breakpoint.height * 0.05}px; min-height: ${$vuetify.breakpoint.height * 0.05}px`">
          <template v-for="tab in tabs">
            <v-tab class="rounded-xl" :key="tab.name"
                   @change="getSBIByPeriod(tab.period)"
                   :active-class="tab.name === tab.name ? 'elevation-2 white' : ''"
                   :ripple="false">{{ tab.name }}</v-tab>
          </template>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <template v-for="tab in tabs">
            <v-tab-item :key="tab.name">
              <template v-if="tab.data">
                <v-card flat>
                  <v-card-text class="px-1">
                    <keep-alive>
                      <component :is="tab.content"
                                 :component-data="tab.data"></component>
                    </keep-alive>
                  </v-card-text>
                </v-card>
              </template>
            </v-tab-item>
          </template>
        </v-tabs-items>
      </v-card>
    </v-col>
    <v-col cols="12" lg="6" xl="5">
      <v-card :max-width="`${$vuetify.breakpoint.lgAndDown ? 100 : 90}%`"
              :max-height="isBoolean ? '75%' : '25%'"
              :min-height="isBoolean ? '40%' : '20%'"
              class="pa-4 mt-16 align-self-center mx-xl-auto">
        <template v-if="isBoolean">
          <v-card-subtitle class="d-flex justify-center">
            <v-icon large color="primary">mb-document</v-icon>
            <span class="ml-5 text-h6">Daily Report</span>
          </v-card-subtitle>
          <v-card-title class="d-flex justify-center text-h5">
            How was your day, {{ username }}?
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn :to="{ name: 'dashboard.report' }" color="primary" width="33%" height="49" rounded class="d-flex align-center font-weight-bold">
              <span class="text-capitalize">Fill in</span>
            </v-btn>
          </v-card-actions>
        </template>
        <template v-else>
          <v-card-text>
            <v-card flat class="rounded-lg">
              <v-card-text class="d-flex align-baseline justify-space-between">
                <div class="d-flex justify-center align-center">
                  <span class="mx-auto">Day is&nbsp;</span>
                  <template v-if="report.status">
                    <span class="text-h6" :style="`color: ${report.status.color}`">{{ report.status.value }}</span>
                  </template>
                </div>
                <div class="d-flex justify-center align-center">
                  <span class="mx-auto">Day Rate:&nbsp;</span><strong>{{ report.day_rate }}</strong>
                </div>
                <div class="d-flex justify-center align-center">
                  <span class="mx-auto">Effort Rate:&nbsp;</span><strong>{{ report.effort_rate }}</strong>
                </div>
              </v-card-text>
            </v-card>
          </v-card-text>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "Activity",
  data () {
    return {
      tabs: [
        {
          name: 'today',
          content: 'today-tab',
          data: null,
          period: 1,
        },
        {
          name: 'week',
          content: 'week-tab',
          data: null,
          period: 7
        },
        {
          name: 'month',
          content: 'month-tab',
          data: null,
          period: 30
        },
        {
          name: 'year',
          content: 'year-tab',
          data: null,
          period: 365
        }
      ],
      tab: 0,
      reported: null,
      report: {},
    }
  },
  beforeMount() {
    this.getSBIByPeriod(1)
    this.getReported()
  },
  mounted() {
    this.$root.$on('update-report', (report) => {
      this.report = report
    })
  },
  methods: {
    getSBIByPeriod (period) {
      this.$store.dispatch('setPeriod', period)
      this.$http.get(`${process.env.VUE_APP_API_URL}/sbi/${Number.parseInt(period)}`)
          .then(response => {
            this.tabs[this.tab].data = response.data
            this.checkPriorities(period)
          })
    },
    checkPriorities (period) {
      if (period === 1) {
        if (this.tabs[this.tab].data.overview.priorities.done === 0 && this.tabs[this.tab].data.overview.priorities.open === 0) {
          this.$store.dispatch('setResponse', { status: true, type: 'warning', message: 'It seems that you didn\'t add today\'s priorities yesterday' }).catch()
        }
      }
    },
    getReported () {
      this.$http.get(`${process.env.VUE_APP_API_URL}/reported`)
          .then((response) => {
            // eslint-disable-next-line no-prototype-builtins
            if (response.data.hasOwnProperty('reported')) {
              this.reported = response.data.reported
            } else {
              this.report = response.data.report
            }

          })
          .catch(() => {
            this.reported = false
          })
    }
  },
  computed: {
    username () {
      return this.$store.getters.GET_USER.first_name
    },
    show () {
      return this.tab && this.tabs[this.tab].period > 1
    },
    dayEffort () {
      return (this.report.effort_rate * 100) / 10
    },
    dayRate () {
      return (this.report.day_rate * 100) / 10
    },
    isBoolean () {
      return typeof this.reported === 'boolean'
    }
  }
}
</script>

<style scoped>

</style>
